<template>
  <div class="team_search">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="team_search__container container">
        <div class="team_search__container__right__table">
          <div class="team_search__container__right__table__title">
            チーム一覧
          </div>
          <div class="team_search__container__right__table__pagination">
            {{ state.displayListTeams.length }}件表示 /
            {{ state.listTeams.length }}件中
          </div>
          <div class="team_search__container__right__table__detail">
            <table>
              <thead>
                <tr>
                  <th>
                  </th>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'name' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="sortTable('name')"
                    >
                      チーム名
                    </a>
                  </th>
                  <th>
                  </th>
                  <th>
                    <a
                      :class="{
                        active:
                          state.fieldSortName == 'is_project_assign' &&
                          state.fieldSortType == 'asc',
                      }"
                      @click="!state.preload && sortTable('is_project_assign')"
                      >
                        ステータス
                      </a>
                  </th>
                </tr>
              </thead>
              <tbody v-if="state.preload">
                <tr>
                  <td colspan="5">
                    <div
                      v-for="idx in 5"
                      :key="idx"
                      class="preloader"
                    ></div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="state.displayListTeams.length">
                <tr
                  v-for="team of state.displayListTeams"
                  :key="team.id"
                >
                  <td>
                    <img
                        @error="replaceByDefault"
                        :src="team.image_file_name || noPhoto"
                        :alt="team.name"
                    />
                  </td>
                  <td>
                      <router-link :to="routePath.TEAMS + '/' + team.id">
                        {{ team.name }}
                      </router-link>
                  </td>
                  <td>
                      <router-link :to="'/user/profile/' + team.owner_id + '/info'">
                        {{ team.owner_nickname }}
                      </router-link>
                  </td>
                  <td>
                      {{ team.is_project_assign ? 'PJ稼働中' : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="team_search__container__right__table__detail__no_data"
            v-if="state.displayListTeams.length == 0 && !state.preload"
          >
            {{ state.aLang.team_list_table_no_data }}
          </div>
        </div>
      </div>
    </div>
  <Alert
    v-if="!!state.alert"
    :content="state.alert"
    @close="() => state.alert = null"
  />
</template>
<script lang="ts" src="./List.ts" />
<link type="scss" src="./List.scss" />
