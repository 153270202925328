import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { orderBy, cloneDeep } from "lodash";
import LanguageUtil from "@/commons/LanguageUtil";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import RoutePath from "@/commons/RoutePath";
import ProjectSearchPreloader from "@/presentation/components/preloader/project-search-preloader/ProjectSearchPreloader.vue";
import DIContainer from "@/core/DIContainer";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import noPhoto from "@/assets/images/common/error_project.png";
import DatePicker from "@/presentation/components/datepicker/DatePicker.vue";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
export default defineComponent({
    name: "ProjectSearch",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        ProjectSearchPreloader: ProjectSearchPreloader,
        DatePicker: DatePicker,
        Alert: Alert
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var routePath = RoutePath;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/',
                    name: "ダッシュボード"
                },
                {
                    route: RoutePath.TEAMS,
                    name: "チーム一覧"
                }
            ],
            phases: [
                { id: 1000, name: 'デジタル化の可能性検討' },
                { id: 1001, name: 'データ分析を通じたデータ・デジタル技術の活用可能性の設計/初期的な検証' },
                { id: 1002, name: 'データ・デジタル技術を用いた新規事業検討' },
            ],
            listTeams: [],
            displayListTeams: [],
            fieldSortName: "",
            fieldSortType: "",
            filter: {
                phases: [],
            },
            toggleFilter: {
                phases: true,
            },
            filterConditionName: {
                phases: '選択なし',
            },
            loading: false,
            preload: true,
            alert: null
        });
        var timeout = null;
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        function onChangeFilterPhases() {
            var cloneList = cloneDeep(state.listTeams);
            var phases = state.filter.phases;
            if (!(phases === null || phases === void 0 ? void 0 : phases.length)) {
                state.displayListTeams = cloneList;
                return;
            }
            state.displayListTeams = cloneList.filter(function (row) {
                for (var _i = 0, _a = row.project_categories; _i < _a.length; _i++) {
                    var project = _a[_i];
                    if (phases === null || phases === void 0 ? void 0 : phases.includes(project.id)) {
                        return true;
                    }
                }
            });
        }
        function sortTable(field) {
            if (field === state.fieldSortName) {
                state.fieldSortType = state.fieldSortType === "desc" ? "asc" : "desc";
            }
            else {
                state.fieldSortType = "asc";
            }
            state.fieldSortName = field;
            var cloneList = cloneDeep(state.displayListTeams);
            state.displayListTeams = orderBy(cloneList, [state.fieldSortName], [state.fieldSortType]);
        }
        function getListTeams() {
            var teamInteractor = DIContainer.instance.get(TeamInteractor);
            return teamInteractor.getTeams().then(function (result) {
                if (!result.data || result.total_count == 0) {
                    state.loading = false;
                    state.preload = false;
                    return;
                }
                ;
                state.listTeams = result.data;
                state.displayListTeams = result.data;
                state.loading = false;
                state.preload = false;
            }).catch(function (error) {
                state.loading = false;
                state.preload = false;
            });
        }
        function replaceByDefault(e) {
            e.target.src = noPhoto;
        }
        /**
         * フィルターを閉じた際に選択中の要素に応じて表示する名称を取得する
         *
         * @param list フィルターの全選択肢の一覧
         * @param selected 選択中の要素のidの一覧
         * @returns 選択中の要素が一つ以上存在する場合はそれらを'、'でjoinした文字列、
         *          一つも存在しない場合は'選択なし'
         */
        function getFilterName(list, selected) {
            return selected.length ?
                list.filter(function (item) { return selected.includes(item.id); })
                    .map(function (item) { return item.name; })
                    .join('、')
                : '選択なし';
        }
        /**
         * フィルターを閉じる際に呼び出されるイベント。
         * 選択肢の表示をトグルする。
         *
         * @param target イベント呼び出し元のフィルター種別名称
         */
        function onToggleFilter(target) {
            var name = '';
            switch (target) {
                case 'phases':
                    name = getFilterName(state.phases, state.filter.phases);
                    break;
            }
            state.filterConditionName[target] = name;
            state.toggleFilter[target] = !state.toggleFilter[target];
        }
        onMounted(function () {
            var categorySearch = store.state.categoryIdSearch;
            if (categorySearch) {
                state.filter.categories.push(categorySearch);
                store.commit("setCategoryIdSearch", null);
            }
            getListTeams();
            if (store.state.deletedTeamAlert) {
                state.alert = store.state.deletedTeamAlert.name + "\u3092\u524A\u9664\u3057\u307E\u3057\u305F";
                store.commit('setDeletedTeamAlert', null);
            }
        });
        return {
            state: state,
            routePath: routePath,
            onChangeFilterPhases: onChangeFilterPhases,
            onToggleFilter: onToggleFilter,
            sortTable: sortTable,
            replaceByDefault: replaceByDefault,
            noPhoto: noPhoto
        };
    }
});
